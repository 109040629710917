<template>
  <div class="side-menu">
    <b-list-group style="text-align: left">
      <b-list-group-item button style="background-color: #121521; color: white;">
        <b-icon icon="bar-chart-line" style="margin-right: 1em; color: #CBA661"></b-icon>
        <router-link class="link-color" :to="{ name: 'CmsDashboard' }" @click.prevent>Dashboard</router-link>
      </b-list-group-item>
      <b-list-group-item button style="background-color: #121521; color: white"></b-list-group-item>
      <b-list-group-item button style="background-color: #121521; color: white">
        <b-icon icon="star" style="margin-right: 1em; color: #CBA661"></b-icon>
        <router-link class="link-color" :to="{ name: 'CmsMenu' }" @click.prevent>Menu</router-link>
      </b-list-group-item>
      <b-list-group-item button style="background-color: #121521; color: white">
        <b-icon icon="receipt" style="margin-right: 1em; color: #CBA661"></b-icon>
        <router-link class="link-color" :to="{ name: 'CmsALaCarte' }" @click.prevent>À la Carte</router-link>
      </b-list-group-item>
      <b-list-group-item button style="background-color: #121521; color: white">
        <b-icon icon="collection" style="margin-right: 1em; color: #CBA661"></b-icon>
        <router-link class="link-color" :to="{ name: 'CmsCategories' }" @click.prevent>Categories</router-link>
      </b-list-group-item>
      <b-list-group-item button style="background-color: #121521; color: white"></b-list-group-item>
      <b-list-group-item button style="background-color: #121521; color: white">
        <b-icon icon="cash" style="margin-right: 1em; color: #CBA661"></b-icon>
        <router-link class="link-color" :to="{ name: 'CmsCashCounts' }" @click.prevent>Cash Counts</router-link>
      </b-list-group-item>
      <b-list-group-item button style="background-color: #121521; color: white">
        <b-icon icon="arrow-down-up" style="margin-right: 1em; color: #CBA661"></b-icon>
        <router-link class="link-color" :to="{ name: 'CmsEntries' }" @click.prevent>Entries</router-link>
      </b-list-group-item>
      <b-list-group-item button style="background-color: #121521; color: white">
        <b-icon icon="card-checklist" style="margin-right: 1em; color: #CBA661"></b-icon>
        <router-link class="link-color" :to="{ name: 'CmsInventory' }" @click.prevent>Inventory</router-link>
      </b-list-group-item>
      <b-list-group-item button style="background-color: #121521; color: white">
        <b-icon icon="calendar-date" style="margin-right: 1em; color: #CBA661"></b-icon>
        <router-link class="link-color" :to="{ name: 'CmsSchedule' }" @click.prevent>Schedule</router-link>
      </b-list-group-item>
      <b-list-group-item button style="background-color: #121521; color: white">
        <b-icon icon="person" style="margin-right: 1em; color: #CBA661"></b-icon>
        <router-link class="link-color" :to="{ name: 'CmsUsers' }" @click.prevent>Users</router-link>
      </b-list-group-item>
      <b-list-group-item button style="background-color: #121521; color: white"></b-list-group-item>
      <b-list-group-item button style="background-color: #121521; color: white">
        <b-button  @click.prevent="logout()" type="submit" variant="danger">Logout</b-button>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
export default {
  methods: {
    logout () {
      this.$store.dispatch('logout')
    }
  }
}
</script>

<style scoped>
  .side-menu {
    margin-left: auto;
    margin-right: auto;
    overflow: auto;
    height: 60%;
    position: fixed;
  }
</style>