<template>
  <div id="cms-a-la-carte-delete">
    <b-container fluid>
      <b-row>
        <b-col></b-col>
        <b-col><h2 style="color: white; margin-bottom: 1em">À la Carte - Delete</h2></b-col>
        <b-col></b-col>
      </b-row>
      <b-row>
        <b-col>
          <SideMenu></SideMenu>
        </b-col>
        <b-col class="col-sm-9">
          <div v-if="loading" class="text-center">
            <b-spinner variant="secondary" label="Loading"></b-spinner>
          </div>
          <div v-else class="col-sm-9" style="color: white; text-align: right">
            <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    label="Item name:"
                    label-for="item-name"
            >
              <b-form-input id="item-name" class="red" v-model="menuItem.item_name" disabled></b-form-input>
            </b-form-group>
            <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    label="Category:"
                    label-for="category_name"
            >
              <b-form-input id="category_name" class="red" v-model="menuItem.category_name" disabled></b-form-input>
            </b-form-group>
            <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    label="Remark in English:"
                    label-for="remark-en"
            >
              <b-form-input id="remark-en" class="red" v-model="menuItem.remark_en" disabled></b-form-input>
            </b-form-group>
            <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    label="Remark in Dutch:"
                    label-for="remark-nl"
            >
              <b-form-input id="remark-nl" class="red" v-model="menuItem.remark_nl" disabled></b-form-input>
            </b-form-group>
            <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    label="Price:"
                    label-for="price"
            >
              <b-form-input id="price" class="red" v-model="menuItem.price" disabled></b-form-input>
            </b-form-group>
            <router-link class="link-color" to="/cms/a-la-carte" @click.prevent>
              <b-button type="reset" variant="dark">Cancel</b-button>
            </router-link>
            <b-button  @click.prevent="deleteMenuItem" type="submit" variant="danger">Delete</b-button>

          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import SideMenu from '@/components/SideMenu.vue'
  import axios from "axios";

  export default {
    data() {
      return {
        loading: true,
        menuItem: [],
      }
    },
    components: {
      SideMenu
    },
    beforeCreate () {
      this.axios
          .get(`https://batavia-backend.herokuapp.com/api/menu-item/${this.$route.params.id}`)
          .then(response => (
              this.menuItem = response.data
          ))
          .finally(() => {
            this.menuItem['price'] = this.menuItem['price'].toFixed(2)
            this.loading = false
          })
    },
    methods: {
      deleteMenuItem() {
        this.axios
            .delete(
                `https://batavia-backend.herokuapp.com/api/menu-item/${this.$route.params.id}`,
                {},
                {
                  headers: {
                    Authorization: axios.defaults.headers.common.Authorization
                  }
                }
            )
            .then(() => {
              this.$router.push({ name: 'CmsALaCarte' })
            })
      }
    }
  }
</script>

<style scoped>
  .red {
    color: #FF0016;
  }
</style>